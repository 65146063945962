// used in conjuncture with styleguide's package.json and browserify-shim

// var $ = global.jQuery = require('jquery');

var Prism = require('./customizer/js/prism.js');

// require('./sg/jquery.magnific-popup.js');
// require('./sg/validetta.js');

var Accordion = require('./sg/accordion/accordion.js');
var Clipboard = require('./sg/clipboard.min.js');

class Styleguide {

	constructor(el) {

		this.$el				= el;
		this.$window			= $(window);

		this.$html				= $('html');
		this.$body				= $('body');

		this.$toggleSidebar		= $('.toggle-sidebar');
		this.$header			= $('.styleguide-header');
		this.$headerBreakpoints	= $('.styleguide-header-breakpoints');

		this.moduleCommentsUrl 	= '/modules.json';
		this.buildNotesUrl 		= '/build-notes.json';

		this.moduleComments 	= null;
		this.buildNotesComments = null;

		this.init();

	};

	init() {

		this.sidebarToggle();
		this.breakpointToggle();

		this.modals();
		this.tabs();
		this.accordions();
		this.clipboard();
		this.forms();

		this.ajaxPageNav();

		this.iframe();
	};

	clipboard() {

		var clipboard = new Clipboard('.js-copy-code');

		clipboard.on('success', function(e){
			e.clearSelection();
		});

		clipboard.on('error', function(e) {
			console.error('Action:', e.action);
			console.error('Trigger:', e.trigger);
		});
	};

	accordions() {
		$('[data-accordion]').each(function() {
			new Accordion($(this));
		});
	};

	modals() {

		$('[data-inline-popup]').magnificPopup({
			type: 'inline',
			overflowY: 'scroll', // as we know that popup content is tall we set scroll overflow by default to avoid jump, mostly in cases of modals in short windows cutting off the close btn if placed outside the modal
			removalDelay: 500, //delay removal by X to allow out-animation
			midClick: true, // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
			callbacks: {

				beforeOpen: function(e) {

					let attr			= this.st.el.attr('data-effect');
					this.st.mainClass	= typeof attr !== typeof undefined && attr !== false ? attr : 'mfp-fade-in';

				}

			}
		});

		$(document).on('click', '.btn--close', function(){
			$.magnificPopup.close();
		});

		var $minwidthmodal = $('#min-width-modal');

		this.$window.resize( (e) => {
			if ( this.$window.width() < 1024 && $minwidthmodal.length > 0 ) {
				// show min width modal
				$.magnificPopup.open({
					showCloseBtn: false,
					closeOnBgClick: false,
					items: {
						src: $minwidthmodal, // can be a HTML string, jQuery object, or CSS selector
						type: 'inline'
					}
				});
			}
			else {

				if( $minwidthmodal.length > 0 ){
					$.magnificPopup.close({
						items: {
							src: $minwidthmodal, // can be a HTML string, jQuery object, or CSS selector
							type: 'inline'
						}
					});
				}
			}
		});

		this.$window.trigger('resize');
	};

	forms() {

		$('form').validetta({
			display: 'inline',
			showErrorMessages: 	false,
			onError: function(e){
				e.preventDefault();
			}
		});

		$('.btn--close').on('click', function(e){
			e.preventDefault();
			$.magnificPopup.close();
		});

		$('#request-access form').on('submit', function(e){

			e.preventDefault();

			var url = e.target.action.value;

			if( url ){

				// Get the post data
				var data = $(this).serialize();

				// Send it to the server
				$.post('/', data, function(response) {
					if (response.success) {
						$.magnificPopup.open({
							items: {
								src: '#request-access-success',
								type: 'inline'
							}
						});
					} else {
						console.log(response);
					}
				});
			}
		});
	};

	tabs() {

		$('[data-type="tabs"]').each(function(e){

			const $this	   = $(this),
				  $tab	   = $this.find('[data-tab]'),
				  $content = $this.find('[data-tab-content]');

			//  Add active states to first tab and link
			$tab.eq(0).addClass('is-active');
			$content.eq(0).addClass('is-active');

			$tab.on('click', function(e) {

				const $el = $(this);

				e.preventDefault();

				if ( $el.is('.is-active') ) return;

				$tab.removeClass('is-active');
				$content.removeClass('is-active');

				$el.addClass('is-active');
				$content.filter('[data-tab-content="' + $el.data('tab') + '"]').addClass('is-active');

			});

		});

	};

	sidebarToggle() {

		this.$toggleSidebar.on('click', (e) => {
			this.$body.add(this.$html).toggleClass('sidebar-open');
		});

		var width = this.$window.width();

		// toggle sidebar on resize
		var sidebarToggle = (e) => {
			if ( width < 1220 ) {
				this.$body.add(this.$html).removeClass('sidebar-open');
			} else {
				// this.$body.add(this.$html).addClass('sidebar-open');
			}
		}

		sidebarToggle();

		this.$window.resize( (e) => {
			if ( this.$window.width() != width ) {
				// DO RESIZE
				width = this.$window.width();
				sidebarToggle();
			}
		});

	};

	breakpointToggle(){

		var pathname = window.location.pathname; // Returns path only
		var url      = window.location.href;     // Returns full URL
		// console.log(pathname, url);

		this.$headerBreakpoints.find('a').on('click', (e) => {

			var $this	= $(e.currentTarget);
			var size	= $this.data('breakpoint-size');

			this.$headerBreakpoints.find('a').removeClass('active');

			$this.addClass('active');
			$('iframe').attr('data-breakpoint-size', size);

		});
	};

	ajaxPageNav() {

		// append to url query string of pages styleguide and reference it on load
		// e.g. styleguide.html?styleguide=global

		$('#sg-nav').find('a').on('click', function(e){

			e.preventDefault();

			var $this       = $(this),
				origin		= window.location.origin,
				href		= $this.attr('href'),
				newURL      = ( $this.hasClass('sg--mods-link') ) ? '?styleguide=' + $this.attr('href').split('/')[0] : '/';

			$.get(origin + '/' + href, function(data) {

				// console.log(data);

				if ( href.slice(-15) !== 'customizer.html' ) {

					$('.styleguide-iframe-wrapper iframe').attr('src', href);

					history.pushState('', '', newURL);

					// close sidebar
					$('html, body').removeClass('sidebar-open');

					// Update sidebar title
					var section = $this.closest('[data-section]').attr('data-section');
					var title = $this.html();
					var html = '<span>' + section + '</span><em>-</em>' + title;

					// console.log(html);

					$('.sidebar .compact-nav .sidebar-title').html(html);

				} else {
					window.open(href, '_top');
				}
			})
				.fail(function(){
					// console.log('nothing');
				});

		});
	};

	iframe() {

		var self = this;

		$.get(this.moduleCommentsUrl, function(data){
			self.moduleComments = data;
		});

		//

		$('iframe').load(function() {

			// update overview notes
			frames[0].$('body').find('.styleguide-module__title--small').each(function(i,el){

				// var title = $this.parents('.styleguide-module__header').find('.styleguide-module__title--small').text();
				var $title = $(el);
				var title = $title.text().toLowerCase();
				var $header = $title.parent();

				$.each(self.moduleComments.data, function(i,el){

					if( el.title.toLowerCase() == title.toLowerCase() ){

						var overview = el.overview;
						if(overview){
							$header.append('<p class="styleguide-module__paragraph">' + overview + '</p>');
						}

						return false;
					}
				});
			});

			// toggle notes
			frames[0].$('body').find('.styleguide-module__header').each(function(){
				$(this).append('<div class="styleguide-module__toggles"></div>');
				// $(this).find('.styleguide-module__toggles').append('<a href="javascript:;" class="styleguide-module__toggle-notes"></a>');
			});

			var codeSnippets = function(){

				var codeToCreateSnippetClass = '.snippet',
					codeSnippetsClass        = '.styleguide-module__toggle-code';

				/**
				* Setup markup code snippet.
				* It gets the HTML of the element and creates the code area
				*/

				Prism.plugins.NormalizeWhitespace.setDefaults({
					'remove-trailing': true,
					'remove-indent': true,
					'left-trim': true,
					'right-trim': true
				});

				var options = {
					"indent": "auto",
					"indent-spaces": 2,
					"wrap": 80,
					"markup": true,
					"output-xml": false,
					"numeric-entities": true,
					"quote-marks": true,
					"quote-nbsp": false,
					"show-body-only": true,
					"quote-ampersand": false,
					"break-before-br": true,
					"uppercase-tags": false,
					"uppercase-attributes": false,
					"drop-font-tags": true,
					"tidy-mark": false,
					"quiet": "yes",
					"show-warnings": "no",
				};

				$.each(frames[0].$(codeToCreateSnippetClass), function(index, val) {

					var snippetClassName	= codeToCreateSnippetClass.substr(1);
					var snippet				= frames[0].$(val)[0].innerHTML;

					frames[0].$(val).siblings('.styleguide-module__header').find('.styleguide-module__toggles').append('<a href="javascript:;" class="' + codeSnippetsClass.replace('.', '') + '"></a>');

					frames[0].$(val).after('<pre id="markup" class="language-markup"><code>' + frames[0].$('<p/>').text(snippet).html() + '</code></pre>').next().hide();

				});

			};

			var updateComments = function(el){

				var $tab = $('.styleguide-panel').find('[data-tab-content="notes"]');

				if( el ){
					$tab.find('.notes-intro').html('');
					$tab.find('.notes-intro').append('<h6>' + el.title + ' Notes' + '</h6>');
					$tab.find('.notes-intro').append('<p>' + el.overview + '</p>');

					$tab.find('.notes-body').html('').html(el.body);
				}
				else {
					$tab.find('.notes-intro').html('');
					$tab.find('.notes-intro').append('<h6>There are no notes for this module</h6>');
					$tab.find('.notes-body').html('');
				}
			};

			//

			codeSnippets();

			// slide out panel
			frames[0].$('body').find('.styleguide-module__toggle-notes, .styleguide-module__toggle-code').on('click', function(e){

				e.preventDefault();

				var $this = $(this),
					$code = $this.parents('.styleguide-module__header').siblings('.language-markup'),
					title = $this.parents('.styleguide-module__header').find('.styleguide-module__title--small').text();

				$('body').addClass('styleguide-panel--open');

				$('[data-tab-content="code"]').find('.language-markup').remove();
				$('[data-tab-content="code"]').append($code).scrollTop(0);
				$('[data-tab-content="code"]').find('.code-intro h6').text(title + ' Code');

				$code.toggle();

				Prism.highlightAll();

				if( self.moduleComments ){

					$.each(self.moduleComments.data, function(i,el){
						if( el.title.toLowerCase() == title.toLowerCase() ){
							updateComments(el);
							return false;
						}
						else {
							updateComments(false);
						}
					});
				}

				// toggle the right tab
				if ( $this.is('.styleguide-module__toggle-notes') ) {
					$('.styleguide-panel').find('[data-tab="notes"]').trigger('click');
				} else {
					$('.styleguide-panel').find('[data-tab="code"]').trigger('click');
				}

			});

			// close it
			$('.styleguide-panel .close').on('click', function(e) {
				$('body').removeClass('styleguide-panel--open');
			});

		});

	};

}

new Styleguide();


// window/iframe related functions that require the onload event outside the class

function getParameterByName(name, url) {

	if ( ! url ) url = window.location.href;

	name        = name.replace(/[\[\]]/g, "\\$&");

	var regex	= new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results	= regex.exec(url);

	if ( !results ) return null;

	if ( !results[2] ) return '';

	return decodeURIComponent(results[2].replace(/\+/g, " "));
};


$(window).load(function() {

	$('body').addClass('loaded');

	var field			= 'styleguide';
	var url				= window.location.href;
	var styleguideURL	= '/' + getParameterByName('styleguide') + '/styleguide.html';

	if ( url.indexOf('?' + field + '=') != -1 ) {
		$('.styleguide-iframe-wrapper iframe').attr('src', styleguideURL);
		setTimeout(function() {
			// console.log(styleguideURL);
			// console.log($('#sg-nav').find('a[href='+ styleguideURL +']'), styleguideURL);
			$('#sg-nav').find('a[href^="/'+ styleguideURL +'"]').after($('.sidebar #nav'));
		}, 350);
	}

});